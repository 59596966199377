{
  "urls": {
    "backgroundUrl" : "https://opencache{2-3}.statkart.no/gatekeeper/gk/gk.open_wmts",
    "backgroundUrlNib" : "https://gatekeeper{1-3}.geonorge.no/BaatGatekeeper/gk/gk.nib_utm35_wmts_v2",
    "ReindriftUrl" : "https://wms.nibio.no/cgi-bin/reindrift?",
    "bgFinCapabilitiesUrl" : "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/WMTSCapabilities.xml?",
    "bgFinCapabilitiesUrlRest" : "https://karttakuva.maanmittauslaitos.fi/maasto/wmts/1.0.0/WMTSCapabilities.xml",
    "bgFinCapabilitiesUrlWMS" : "https://karttakuva.maanmittauslaitos.fi/maasto/wmts?service=WMTS&request=GetCapabilities&version=1.0.0",
    "fin_api_key": "api-key=456562e9-1f07-49a0-86d1-819c249d9229&",
    "basicAuth": "Basic bGFuZGJydWtzZGlyZWt0b3JhdGV0a29lOlJlaW5kcmlmdF90ZXN0XzIwMjE=",
    "nor_backgroundUrl" : "https://cache.kartverket.no/v1/wmts/1.0.0/",
    "nor_backgroundUrlNib" : "https://gatekeeper{1-3}.geonorge.no/BaatGatekeeper/gk/gk.nib_utm35_wmts_v2?gkt="
  }
}
