import { jsPanel } from 'jspanel4';


var legendPanel = null;

export function initLegend(mapIn) {
  var legendButton = document.getElementById('legendButton');
  legendButton.style.cursor = 'pointer';
  legendButton.onclick = function() {
    if (legendPanel){
      legendPanel.close();
    } else {
      showlegend(mapIn);
    }
  };
}

export function showlegend(map) {

  legendPanel =  jsPanel.create({
         id: 'legendPanel',
         headerTitle: 'Legenda/tegnforklaring',
         theme: 'primary',
         contentSize: 'auto 190',
         position: {
              my: 'left-center',
              at: 'left-center',
              offsetX: '50px',
              autoposition: 'down'
          },
         content: '<div id="panelContent"></div>'
     });

   updateLegend(map);
}

const updateLegend = function (map) {
  let html = '';
  map.getLayers().getArray().slice().forEach(layer => {
    const layerLegend = layer.get('legend');
    const layerName = layer.get('title');

    if (layerLegend) {
      const wmsSource = layer.getSource();
      const resolution = map.getView().getResolution();
      let graphicUrl = wmsSource.getLegendUrl(resolution);

      graphicUrl = graphicUrl + '&sld_version=1.1.0';

      html += "<div id='"+layerName+"'><img src='"+graphicUrl+"'></div>";
    }
  });
let divLegend = document.getElementById('panelContent');
divLegend.innerHTML = html;
}

// setup event handler function
let handler = function(event) {
    legendPanel = null;
}

// assign handler to event
document.addEventListener('jspanelclosed', handler, false);
