import config from "./config.json";
import ImageWMS from "ol/source/ImageWMS";
import ImageLayer from "ol/layer/Image";

export default class ConventionFence {
  static layersConfig = {
    wms_name: "convention_fence_FIN_NOR",
    layers: [
      {
        id: "conventionFence",
        layer_name: {
          fi: "conventionfence_FIN",
          no: "conventionfence_NOR",
        },
        layer_lang_tag: {
          fi: "Poroesteaita Suomi - Norja",
          no: "Konvensjonsgjerde Finland - Norge",
        },
      },
      {
        id: "cottages",
        layer_name: {
          fi: "cottages_FIN",
          no: "cottages_NOR",
        },
        layer_lang_tag: {
          fi: "Kämppä",
          no: "Hytte",
        },
      },
      {
        id: "connectedfence",
        layer_name: {
          fi: "connectedfence_FIN",
          no: "connectedfence_NOR",
        },
        layer_lang_tag: {
          fi: "Liitetty aita",
          no: "Tilknyttet gjerde",
        },
      },
      {
        id: "gates",
        layer_name: {
          fi: "gates_FIN",
          no: "gates_NOR",
        },
        layer_lang_tag: {
          fi: "Portti",
          no: "Port",
        },
      },
      {
        id: "moosegates",
        layer_name: {
          fi: "moosegates_FIN",
          no: "moosegates_NOR",
        },
        layer_lang_tag: {
          fi: "Hirviportti",
          no: "Elgport",
        },
      },
    ],
  };

  constructor() {}

  static createLayers(lang, hostUrl) {
    let returnlayers = [];

    this.layersConfig.layers.forEach((lyrConfig, i) => {
      const url = hostUrl + this.layersConfig.wms_name + "?";
      const layer_source = new ImageWMS({
        //url: 'https://wms03test.nibio.no/cgi-bin/mis',
        url: url,
        crossOrigin: "Anonymous",
        params: {
          LAYERS: lyrConfig.layer_name[lang],
          CRS: "EPSG:25835",
        },
        projection: "EPSG:25835",
      });
      const new_layer = new ImageLayer({
        name: lyrConfig.id,
        id: lyrConfig.id,
        title: lyrConfig.layer_lang_tag[lang],
        opacity: 1,
        source: layer_source,
        legend: "true",
        featureinfo: "true",
      });

      returnlayers.push(new_layer);
    });
    return returnlayers;
  }

  createLayers(lang) {
    return this.createLayers[lang];
  }
}
