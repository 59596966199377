import config from './config.json';

export default class AppEnvironment{

  static env = {
    prod: {
      wmshost: "https://wms.nibio.no/cgi-bin/"
    },
    test: {
      wmshost: "https://wms04test.nibio.no/cgi-bin/"
    },
    development:{
          // wmshost: "https://wms04test.nibio.no"
      wmshost: "https://wms04main.int.nibio.no/cgi-bin/"
    }
  };


  constructor() {
  };


  static get (env) {
    console.log('Skal hente:', this.env);
    if ( typeof env === 'undefined' || typeof this.env[env] === 'undefined') {
      // Always default to development when not defined
      return this.env.development;
    }
    return this.env[env];
  }


}
