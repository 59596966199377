import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./style.css";
import config from "./js/config.json";
import {
  Map,
  View
} from "ol";
import TileLayer from "ol/layer/Tile";
import Zoom from "ol/control/Zoom";
import ZoomSlider from "ol/control/ZoomSlider";
import ZoomToExtent from "ol/control/ZoomToExtent";
import OSM from "ol/source/OSM";
import ImageWMS from "ol/source/ImageWMS";
import ImageLayer from "ol/layer/Image";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";

import Attribution from "ol/control/Attribution";
/*import {
  createStringXY,format
} from 'ol/coordinate';*/
import {
  format,
  toStringXY,
  createStringXY
} from "ol/coordinate";

import MousePosition from "ol/control/MousePosition";
import {
  ScaleLine
} from "ol/control";

import Projection from "ol/proj/Projection";
import {
  get as getProjection
} from "ol/proj";
import {
  getTopLeft,
  getWidth
} from "ol/extent";

import {
  register
} from "ol/proj/proj4";
import proj4 from "proj4";
import {
  get as getProjection
} from "ol/proj";
import {
  getWidth,
  getTopLeft
} from "ol/extent";
import {
  register
} from "ol/proj/proj4";

import WMTS, {
  optionsFromCapabilities
} from "ol/source/WMTS";
import WMTSCapabilities from "ol/format/WMTSCapabilities";
import LayerSwitcher from "ol-layerswitcher";
import fas from "@fortawesome/fontawesome-free";

import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
} from "ol/style";
import {
  Draw,
  Modify
} from "ol/interaction";
import {
  LineString,
  Point
} from "ol/geom";
import {
  OSM,
  Vector as VectorSource
} from "ol/source";
import {
  Tile as TileLayer,
  Vector as VectorLayer
} from "ol/layer";
import { unByKey } from 'ol/Observable';


import {
  jsPanel
} from "jspanel4";
import $ from "jquery";

import LanguageControl from "./js/languageControl.js";
import ConventionFence from "./js/conventionFence.js";
import {
  initLegend,
  showlegend
} from "./js/legend.js";

import AppEnvironment from "./js/environment.js";

const parser = new WMTSCapabilities();
const appEnv = AppEnvironment.get(process.env.CONVENTION_ENV);
let draw; // global so we can remove it later
let mapClick;

const style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    //color: "rgba(249, 132, 229, 1)",
    color: "rgba(255, 0, 212, 0.7)",
    lineDash: [10, 10],
    width: 4,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(255, 0, 212, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
  }),
});

const labelStyle = new Style({
  text: new Text({
    font: "14px Calibri,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(255, 0, 212, 0.7)",
    }),
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15,
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: "rgba(255, 0, 212, 0.7)",
    }),
  }),
});

const tipStyle = new Style({
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(255, 0, 212, 0.4)",
    }),
    padding: [2, 2, 2, 2],
    textAlign: "left",
    offsetX: 15,
  }),
});

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
  }),
  text: new Text({
    text: "Drag to modify",
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(255, 0, 212, 0.7)",
    }),
    padding: [2, 2, 2, 2],
    textAlign: "left",
    offsetX: 15,
  }),
});

const segmentStyle = new Style({
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(255, 0, 212, 0.4)",
    }),
    padding: [2, 2, 2, 2],
    textBaseline: "bottom",
    offsetY: -12,
  }),
  image: new RegularShape({
    radius: 6,
    points: 3,
    angle: Math.PI,
    displacement: [0, 8],
    fill: new Fill({
      color: "rgba(255, 0, 212, 0.4)",
    }),
  }),
});

const segmentStyles = [segmentStyle];

const formatLength = function(line) {
  const length = line.getLength();
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " km";
  } else {
    output = Math.round(length * 100) / 100 + " m";
  }
  return output;
};

const formatArea = function(polygon) {
  const area = polygon.getArea();
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " km\xB2";
  } else {
    output = Math.round(area * 100) / 100 + " m\xB2";
  }
  return output;
};

proj4.defs(
  "EPSG:25835",
  "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3067",
  "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:4258",
  "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs"
);
register(proj4);

var backgroundUrl = config.urls.backgroundUrl;
var backgroundUrlNib = config.urls.backgroundUrlNib;

function checkIsJson(data) {
  if (
    /^[\],:{}\s]*$/.test(
      data
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  } else {
    return false;
  }
}

var resultat = null;
let languageLayersArr = [];
var key = null;
let selectedLanguage = "fi";
$.ajax({
  // url: 'https://kart10utv.int.nibio.no/map/tokenJson.jsp',
  url: "/map/token_json.jsp",
  contentType: "application/json",
  dataType: "text",
  success: function(data, textStatus, jqXHR) {
    if (checkIsJson(data)) {
      resultat = $.parseJSON(data);
    }
    
    if (resultat) {
      key = resultat.token;

      backgroundUrl = config.urls.nor_backgroundUrl;
      backgroundUrlNib = config.urls.nor_backgroundUrlNib + key;

      background_Nor.getSource().setUrl(backgroundUrl);
      norgeibilder.getSource().setUrl(backgroundUrlNib);
    }
  },
});

const projection = getProjection("EPSG:25835");
const projectionExtent = [-3500000.0, 3500000.0, 3045984.0, 9045984.0];

var resolutions = [
  21664,
  10832,
  5416,
  2708,
  1354,
  677,
  338.5,
  169.25,
  84.625,
  42.3125,
  21.15625,
  10.578125,
  5.2890625,
  2.64453125,
  1.322265625,
  0.6611328125,
  0.33056640625,
  0.165283203125,
];

var matrixIds = new Array(resolutions.length);
var matrixIds_nib = new Array(resolutions.length);
for (var z = 0; z < resolutions.length; ++z) {
  matrixIds[z] =  z;
  matrixIds_nib[z] = z;
}

// Background raster norway
var background_Nor = new TileLayer({
  //title: 'Gråtone',
  id: "background_Nor",
  type: "base",
  legend: false,
  source: new WMTS({
    // url: "http://opencache.statkart.no/gatekeeper/gk/gk.open_wmts",
    url: 'https://cache.kartverket.no/v1/wmts/1.0.0/',
    crossOrigin: "Anonymous",
    layer: "topo",
    matrixSet: "utm35n",
    format: "image/png",
    projection: projection,
    tileGrid: new WMTSTileGrid({
      origin: [-3500000.0, 9045984.0],
      resolutions: resolutions,
      matrixIds: matrixIds,
    }),
    style: "default",
    extent: [-3500000.0, 3500000.0, 3045984.0, 9045984.0],
  }),
});

var norgeibilder = new TileLayer({
  title: "Ortokuva Norja/Ortofoto Norge",
  id: "flybilder",
  legend: false,
  //type: 'base',
  visible: false,
  source: new WMTS({
    url: backgroundUrlNib,
    crossOrigin: "Anonymous",
    layer: "Nibcache_UTM35_EUREF89_v2",
    matrixSet: "default028mm",
    format: "image/jpeg",
    projection: projection,
    tileGrid: new WMTSTileGrid({
      origin: [-3500000.0, 9045984.0],
      resolutions: resolutions,
      matrixIds: matrixIds_nib,
    }),
    style: "default",
    extent: [-3500000.0, 3500000.0, 3045984.0, 9045984.0],
  }),
});

var grensepunkt_source = new ImageWMS({
  url: appEnv.wmshost + "convention_fence_FIN_NOR" + "?",
  crossOrigin: "*",
  params: {
    LAYERS: "grensepunkter",
    CRS: "EPSG:25835",
  },
  projection: "EPSG:25835",
});

var grensepunkt_layer = new ImageLayer({
  id: "GRENSEPUNKT",
  legend: true,
  title: "Rajamerkki/Riksgrenserøys",
  opacity: 1,
  source: grensepunkt_source,
});

var coordFormat = function(dgts, InString) {
  return function(coord1) {
    var coord2 = [coord1[1], coord1[0]];
    var template = InString + " {x}, {y}";
    var out = format(coord2, template, dgts);
    return out;
  };
};

var mousePositionControl = new MousePosition({
  coordinateFormat: coordFormat(0, "UTM 35:"),
  projection: getProjection("EPSG:25835"),
  // comment the following two lines to have the mouse position
  // be placed within the map.
  className: "custom-mouse-position",
  target: document.getElementById("mouse-position"),
  placeholder: "UTM 35",
});

var mousePositionControlWGS = new MousePosition({
  coordinateFormat: coordFormat(5, "WGS 84:"),
  projection: getProjection("EPSG:4258"),
  // comment the following two lines to have the mouse position
  // be placed within the map.
  className: "custom-mouse-position-WGS84",
  target: document.getElementById("mouse-position-WGS84"),
  placeholder: "WGS 84",
});

let newIcon = document.createElement("i");
newIcon.classList.add("fas");
newIcon.classList.add("fa-house");

// slett
var extentOptions = {
  extent: [
    214531.9410176677,
    7564606.2225726815,
    560404.0589823322,
    7781749.7774273185,
  ],
  tipLabel: "Zoom to extent",
  label: newIcon,
  className: "conventionfenceExtent",
};

var scaleLine = new ScaleLine({
  bar: true,
  text: true,
  minWidth: 125,
});

// let maastoLayer = null;
// let ortoLayer = null;
let map = null;

const view = new View({
  projection: projection,
  center: [387468, 7673178],
  zoom: 9,
  maxZoom: 20,
  minZoom: 6,
});

var layerSwitcher = new LayerSwitcher({
  activationMode: "click",
  startActive: false,
  tipLabel: "Layers", // Optional label for button
  groupSelectStyle: "children", // Can be 'children' [default], 'group' or 'none'
});

// Background raster Finland

function customLoader(tile, src) {
  // Add Authorization header to all finnish WMTS tiles
  var client = new XMLHttpRequest();
  client.open("GET", src);
  client.responseType = "arraybuffer";
  client.setRequestHeader(
    "Authorization",
    "Basic bGFuZGJydWtzZGlycmFqYTpHcmVuc2VnamVyZGUyMDIyIQ=="
  );
  client.onload = function() {
    var arrayBufferView = new Uint8Array(this.response);
    var blob = new Blob([arrayBufferView], {
      type: "image/png"
    });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(blob);
    tile.getImage().src = imageUrl;
  };

  client.send();
}

function setLanguage(lang, languageSelectButtons) {
  if (selectedLanguage !== lang) {
    selectedLanguage = lang;
    languageLayersArr.forEach((langLayer) => {
      map.removeLayer(langLayer);
    });
    languageLayersArr = ConventionFence.createLayers(lang, appEnv.wmshost);
    languageLayersArr.forEach((langLayer) => {
      map.addLayer(langLayer);
    });
    const id = "select_" + lang;
    for (key in languageSelectButtons) {
      if (key !== lang) {
        languageSelectButtons[key].classList.remove("active");
      } else {
        languageSelectButtons[key].classList.add("active");
      }
    }
  }
  return;
}

function initLanguage() {
  const languageSelect = {
    fi: document.getElementById("select_fi"),
    no: document.getElementById("select_no"),
  };
  document.getElementById("select_fi").onclick = function() {
    setLanguage("fi", languageSelect);
  };
  document.getElementById("select_no").onclick = function() {
    setLanguage("no", languageSelect);
  };
}

let measurePanel = null;
let measureType = 'LineString';
let drawLabel = true;

function showMeasure() {
    measurePanel =  jsPanel.create({
      id: 'measurePanel',
      headerTitle: 'Mittaa / Måle',
      theme: 'primary',
      contentSize: 'auto 90',
      position: {
          my: 'left-center',
          at: 'left-top',
          offsetY: '140px',
          offsetX: '50px',
          autoposition: 'down'
      },
      content: '<form><label><input id="line" type="radio" name="color" value="LineString" checked> Pituus / Lengde</label><br>' +
      '<label><input id="area" type="radio" name="color" value="Polygon"> Pinta-ala / Areal</label><br><br>'+
      '<label id="drawLabel"><input type="checkbox" id="checkLabel" checked> Naytä mittaus / Vis måling</label></form>',
      onclosed: function(panel, closedByUser) {
        removeMeasure();
        measureState = false;
    }
    });

    var lineRadio = document.getElementById('line');
    var areaRadio = document.getElementById('area');
    var drawLabelCheckbox = document.getElementById('checkLabel');

    
    
    // Add click event to each radio button
    
    lineRadio.addEventListener('click', function() {
      console.log('Measure:', this.value);
      measureType = this.value;
      removeMeasure();
      addMeasure(measureType);
      document.getElementById("measure_button").classList.add("activeButton");
    });
    
    areaRadio.addEventListener('click', function() {
      console.log('Measure:', this.value);
      measureType = this.value;
      removeMeasure();
      addMeasure(measureType);
      document.getElementById("measure_button").classList.add("activeButton");
    });

    // Add click event to the checkbox
    drawLabelCheckbox.addEventListener('change', function() {
      drawLabel = this.checked;
    });
    

}









let measureState = false;
document.getElementById("measure_button").onclick = function() {
  measureState = !measureState;
  if (measureState) {
    document.getElementById("measure_button").classList.add("activeButton");
    removeFeatureinfo();
    addMeasure('LineString');
    showMeasure();
  } else {
    removeMeasure();
    addFeatureinfo();
    measurePanel.close();
  }
};

const measureSource = new VectorSource();

const modify = new Modify({
  source: measureSource,
  style: modifyStyle
});

let tipPoint;




function styleFunction(feature, segments, drawType, tip) {
  const styles = [style];
  const geometry = feature.getGeometry();
  const type = geometry.getType();
  let point, label, line;
  if (!drawType || drawType === type) {
    if (type === "Polygon") {
      point = geometry.getInteriorPoint();
      if (drawLabel) {
        label = formatArea(geometry);
      }
      // line = new LineString(geometry.getCoordinates()[0]);
      line = null;
      // debugger;
    } else if (type === "LineString") {
      point = new Point(geometry.getLastCoordinate());
      if (drawLabel) {
        label = formatLength(geometry);
      }
      line = geometry;
    }
  }
  if (segments && line && drawLabel) {
    let count = 0;
    line.forEachSegment(function(a, b) {
      const segment = new LineString([a, b]);
      const label = formatLength(segment);
      if (segmentStyles.length - 1 < count) {
        segmentStyles.push(segmentStyle.clone());
      }
      const segmentPoint = new Point(segment.getCoordinateAt(0.5));
      segmentStyles[count].setGeometry(segmentPoint);
      segmentStyles[count].getText().setText(label);
      styles.push(segmentStyles[count]);
      count++;
    });
  }
  if (label ) {
    labelStyle.setGeometry(point);
    labelStyle.getText().setText(label);
    styles.push(labelStyle);
  }
  if (
    tip &&
    type === "Point" &&
    !modify.getOverlay().getSource().getFeatures().length
  ) {
    tipPoint = geometry;
    tipStyle.getText().setText(tip);
    styles.push(tipStyle);
  }
  return styles;
}

const measurevector = new VectorLayer({
  source: measureSource,
  style: function(feature) {
    return styleFunction(feature, true);
  },
});


function addFeatureinfo() {
  mapClick = map.on('singleclick', function(evt) {

      var html = '';
      var panelHtml = '';
      var htmlOut = '';
      const urls = [];
      const viewResolution = view.getResolutionForZoom(view.getZoom())); map.getLayers().getArray().slice().forEach(layer => {
      const featureInfo = layer.get('featureinfo');
      const layerVisible = layer.getVisible();
      if (featureInfo && layerVisible) {
        const source = layer.getSource();
        const url = source.getFeatureInfoUrl(
          evt.coordinate,
          viewResolution,
          'EPSG:25835', {
            'INFO_FORMAT': 'text/html'
          }
        );
        urls.push(url)
      }
    });

    var nr = 0; urls.map(url => {
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          nr++;

          //panelHtml += "<iframe width='100%' heigth='50%' src="+url+" id='Iframe"+nr+"'></iframe>";
          var parser = new DOMParser();
          var doc = parser.parseFromString(html, 'text/html');
          var table = doc.querySelector('table');
          if (table) {
            panelHtml += new XMLSerializer().serializeToString(table);

          }
          if (urls.length === nr) { // last
            if (panelHtml === '') {
              panelHtml = '<P>Ei osunut mihinkään kohteeseen <BR>Traff ingen objekter</P>';
            }

            jsPanel.create({
              headerTitle: 'Tiedot/Info',
              theme: 'primary',
              contentSize: '400 450',
              content: panelHtml
            });


          }
        });



    });

  });

}

function removeFeatureinfo() {
  unByKey(mapClick);
}

function addMeasure(drawType) {
  map.un('singleclick', mapClick);
  //const drawType = measureType;
  const activeTip =
    "Click to continue drawing the " +
    (drawType === "Polygon" ? "polygon" : "line");
  const idleTip = "Click to start measuring";
  let tip = idleTip;
  draw = new Draw({
    source: measureSource,
    type: drawType,
    style: function(feature) {
      return styleFunction(feature, true, drawType, tip);
    },
  });
  draw.on("drawstart", function() {
    if (true) {
      measureSource.clear();
    }
    modify.setActive(false);
    tip = activeTip;
  });
  draw.on("drawend", function() {
    modifyStyle.setGeometry(tipPoint);
    modify.setActive(true);
    map.once("pointermove", function() {
      modifyStyle.setGeometry();
    });
    tip = idleTip;
  });
  modify.setActive(true);
  map.addInteraction(draw);
}


function removeMeasure() {
  map.removeInteraction(draw);
  measureSource.clear();
  document.getElementById("measure_button").classList.remove("activeButton");
}

function initMap() {
  languageLayersArr = ConventionFence.createLayers("fi", appEnv.wmshost);
  map = new Map({
    target: "map",

    controls: [
      new Zoom(),
      new ZoomSlider(),
      new ZoomToExtent(extentOptions),
      /*new Attribution({
              collapsible: false,
              collapsed: false
            }),*/
      mousePositionControl,
      mousePositionControlWGS,
      scaleLine,
      layerSwitcher,
    ],
    layers: [
      // maastoLayer,
      background_Nor,
      // ortoLayer,
      norgeibilder,
      grensepunkt_layer,
    ],
    view: view,
  });
  languageLayersArr.forEach((langLayer) => {
    map.addLayer(langLayer);
  });
  addFinishMaps(map);
  initLegend(map);
  showlegend(map);

  addFeatureinfo();




  map.addLayer(measurevector);

  map.addInteraction(modify);




}

function addFinishMaps(targetMap) {
  fetch(config.urls.bgFinCapabilitiesUrlWMS, {
      headers: new Headers({
        Authorization: "Basic bGFuZGJydWtzZGlycmFqYTpHcmVuc2VnamVyZGUyMDIyIQ==",
      }),
    })
    .then((response) => response.text())
    .then((text) => {
      const result = parser.read(text);
      const optionsMasto = optionsFromCapabilities(result, {
        layer: "maastokartta",
        matrixSet: "ETRS-TM35FIN",
      });
      const optionsOrto = optionsFromCapabilities(result, {
        layer: "ortokuva",
        matrixSet: "ETRS-TM35FIN",
      });

      //Needed for open finnish maps
      //optionsMasto.urls[0] = optionsMasto.urls[0] + config.urls.fin_api_key;
      //optionsOrto.urls[0] = optionsOrto.urls[0] + config.urls.fin_api_key;

      const maastoLayer = new TileLayer({
        id: "MAASTOLAYER",
        legend: false,
        opacity: 1,
        source: new WMTS(optionsMasto),
      });
      const ortoLayer = new TileLayer({
        id: "ORTOLAYER_FIN",
        legend: false,
        title: "Ortokuva Suomalainen/Ortofoto Finland",
        visible: false,
        opacity: 1,
        source: new WMTS(optionsOrto),
      });

      maastoLayer.getSource().setTileLoadFunction(customLoader);
      ortoLayer.getSource().setTileLoadFunction(customLoader);
      const layers = map.getLayers();
      layers.insertAt(0, maastoLayer);
      layers.insertAt(1, ortoLayer);
    })
    .catch(function(error) {
      alert(
        "Suomen taustakarttatiedot eivät ole tällä hetkellä käytettävissä.\r\nFinsk bakgrunnskart svarer ikke."
      );
    });
}

initMap();



initLanguage();
